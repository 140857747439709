import * as React from 'react';
import Meta from 'components/Meta';
import BlackHeader from 'components/swipr/BlackHeader';
import DescFooter from 'components/DescFooter';

import 'styles/index.scss';

const CommuityGuidelines = () => {
  return (
    <>
      <Meta titleSuffix={'Community Guidelines'} />
      <main className={'desc'}>
        <BlackHeader text={'Community Guidelines'} />
        <div className={'desc-content'}>
          <p>
            BeFriend is a global community, and we’re committed to creating an
            online space where individuals can genuinely connect with new people
            and make new friends, securely and safely. We created these
            guidelines to make sure every user has a safe and positive
            experience on BeFriend. Any violation of these guidelines may result
            in a permanent account ban from BeFriend.
          </p>
          <p>
            <strong>Be Respectful</strong>
            <br />
            We have a zero-tolerance policy for offensive content and abusive
            behavior. Bullying, harassment, discrimination, and inappropriate
            content are strictly prohibited on BeFriend. We take this policy
            very seriously and have specific moderation procedures in place for
            reported users and content. Users are encouraged to help keep
            BeFriend safe by reporting or blocking users who go against this
            policy. Reported content will be removed immediately until it is
            reviewed. Conversations with blocked users will be removed along
            with any type of profile engagement. If you see someone breaking the
            rules anywhere on BeFriend, report them using the Block/Report
            button, and we will take it from there. See the How-To section below
            for step-by-step instructions for reporting and blocking on
            BeFriend.
          </p>

          <p>Things That Are NOT Allowed on BeFriend:</p>

          <ul>
            <li>Pornographic or sexually explicit content</li>
            <li>Nude, underwear, or suggestive images</li>
            <li>
              Promoting violence such as violent acts, guns, knives, or any
              weapons
            </li>
            <li>Gory and graphic images</li>
            <li>Images that promote any drugs or drug usage</li>
            <li>Marketplace activity such as premium</li>
            <li>Snapchat accounts or selling anything of any nature</li>
            <li>Claiming to send or seek nude images</li>
            <li>Hate speech or other abusive behavior</li>
            <li>Any illegal activity or promotion of illegal activity</li>
          </ul>

          <p>
            <strong>Sexually Explicit Content</strong>
            <br />
            BeFriend has a zero-tolerance policy for users promoting and/or
            distributing pornographic content. Saving, asking and/or threatening
            to post explicit content is strictly prohibited.
          </p>

          <p>
            <strong>Spam</strong>
            <br />
            Spam, bot activity, and deceptive practices are not allowed on
            BeFriend. To enforce this policy, we do not allow images or links of
            any kind to be Sent in Direct Messages. Spam and bot-like messages
            are also flagged to our system and are blocked before they are
            received by the end-user. Help keep BeFriend safe by reporting users
            and content that you suspect are spam by tapping “report”. All
            accounts found violating this guideline will be terminated.
          </p>

          <p>
            <strong>Impersonation</strong>
            <br />
            Any attempt to impersonate others is strictly prohibited. This
            includes any type of deception about who you are, whether it’s lying
            about your age, your name, using someone else’s photos, and any
            other type of deception. Help keep BeFriend safe by reporting users
            who you suspect are pretending to be someone they’re not by tapping
            “report”. All accounts found violating this guideline will be
            terminated.
          </p>
          <p>
            <strong>Obey the Law</strong>
            <br />
            Illegal activity of any nature is prohibited on BeFriend. Safety is
            our number one priority. Please do not ever engage in activity or
            post content of any of the following: threats, drugs, weapons,
            violence, underage users, sexual services, hate speech,
            discrimination, impersonation, selling or promoting illegal
            services. Users are encouraged to use the in-app report button for
            any suspected underage user who may be wrongfully using BeFriend.
          </p>
          <p>
            <strong>Crisis Text Line</strong>
            <br />
            BeFriend is a space for you to be your true self. We encourage all
            kinds of self-expression and encourage users to have honest and
            authentic conversations. If you or someone you meet on BeFriend is
            experiencing stress, anxiety, depression or just needs someone to
            talk to, text HELLO to 741741 to chat with a live, trained crisis
            counselor for free 24/7.
          </p>
          <p>
            <strong>How-To:</strong>
            <br />
            HOW TO REPORT / BLOCK SOMEONE FROM THEIR PROFILE
            <br />
            You can report / block any account that distributes or promotes
            inappropriate content, abuse, or spam, or anything else that does
            not follow our Community Guidelines directly from their profile
            page. All reports are secure and your information is never shared
            with the person you are reporting. To report / block someone from
            their profile page:
          </p>
          <ul>
            <li>
              Tap the report emoji in the bottom right corner of any profile
              card
            </li>
            <li>Tap “Report” or “Block”</li>
          </ul>
          <p>HOW TO REPORT / BLOCK SOMEONE FROM THE CHAT SECTION</p>
          <ul>
            <li>
              Tap the report emoji in the top right corner of the chat screen
            </li>
            <li>Tap “Report” or “Block”</li>
          </ul>
          <p>
            We would again like to reiterate how important it is for users to
            report any accounts that violate any of the above guidelines. Your
            reports are super effective in filtering out any and all content
            that doesn’t belong on BeFriend.
          </p>
          <br />
          <br />
          <br />
          <br />
        </div>
        <DescFooter />
      </main>
    </>
  );
};

export default CommuityGuidelines;
